import React from "react";
import "./Hero.css";
import dark_arrow from "../../assets/edusity/dark-arrow.png";

const Hero = () => {
  return (
    <div className="hero container">
      <div className="hero-text">
        <h1> Your Trusted Silver Springs HVAC Experts </h1>
        <p>
          Our mission is to provide comfort, enhance community well-being, and
          support our clients' journeys toward a better quality of life through
          reliable, efficient, and compassionate HVAC services.
        </p>

        <button className="btn">
          {" "}
          Book Now <img src={dark_arrow} alt="" />{" "}
        </button>
      </div>
    </div>
  );
};

export default Hero;
